.angela-container {
    background-image:linear-gradient( rgba(184, 183, 183, 0.6), rgba(3, 3, 3, 0.9) ),  url("../../images/angela/AngelaPool.JPG");
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2em;
    padding-top: 7em;
    display: flex;
    flex-flow: column nowrap;
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 23px;
    justify-content: center;
    align-items: center;
}

.angela-p {
    width: 80vw;
    text-align: left;
}

.angela-title {
    font-family: 'Raleway', sans-serif;
    font-size: 50px;
    font-weight: bold;
    text-shadow: 1px 1px 4px black;
}

.angela-bold {
    font-family: "Sacramento";
    font-size: 90px;
    color: #f1d5a3;
    font-weight: normal !important
}


