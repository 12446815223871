
.carousel .thumb img {
    width: 100% !important;
    object-fit: cover;
}

.carousel .slide img {
    height: 100vh;
    object-fit: cover;
} 


.carousel-overlay {
    position: absolute;
    z-index: 1 !important;
    text-align: center;
    text-emphasis: italic ;
    top: 20vh;
    width: 100%;
    
}

.carousel-title {
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.801);
    color: rgba(255, 255, 255, 0.89);
    font-family: 'Sacramento', cursive;
    font-size: 12vh;
}

.carousel-details {
    display: flex;
    justify-content: space-evenly;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.445);
    color: rgb(255, 255, 255);
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 20px;
    width: 100%;
    font-style: italic;
}

.full-logo-image {
    background: rgb(255, 255, 255,.4)
}

.personal-profile-container {
    display: flex;
    flex-flow: column nowrap;
    background-image:linear-gradient( rgb(24, 30, 55,.90), rgba(24, 30, 55, 0.452) ), url("../../images/NashvilleHighRes.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    height: auto
}

.pp-top-container {
    display:flex;
    flex-flow: row wrap;
    justify-content: center;
}

.angela-img-container {
    background-image: url("../../images/angela/AngelaCheek.JPG");
    width: 30vw;
    height:60vh;
    min-width: 330px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    margin: 2em
}

.angela-profiletext-container {
    font-family: 'Raleway', sans-serif;
    margin-left: 1em;
    margin-top: 16px;
    max-width: 900px;
    font-size: 1.3em;
    margin: 2em;
    color: white;
    text-align: left
}

.people-are-talking {
    color: white;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    margin-bottom: 2em;
}

.pat-title {
    width: 100%;
    text-align: center;
    margin-left: 2em
}

.snippets-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center
}

.snippet-item {
    width: 300px;
    display: inline-grid;
    height: 80px;
    margin: 1em;
    background: linear-gradient(140.42deg, #f1d5a3 50.45%, #FFFFFF 106.68%);
    color: black;
    font-weight: bold;
    border-radius: 5px;
    align-items: center;
    padding: .1em;
    justify-content: center;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.253)
}

.readmore-button {
    height: 50px;
    background: none !important;
    border: 2px solid white !important;
    border-radius: 5px;
    transition: .2s ease-in
}

.readmore-button:hover, .readmore-button:focus {
    box-shadow: 0 0.5em 0.5em -0.4em #f1d5a3;
    transform: translateY(-0.25em);
}

.contactme-button {
    margin: 1em;
    margin-left: 4em;
    width: 20%;
    background:none !important;
    box-shadow: 3px 3px rgba(0, 0, 0, 0.308);
    height: 75px;
    font-size: 30px !important;
    font-weight: bolder !important;
    border: 2px solid white !important;
    border-radius: 5px !important;
    transition: .25s
}

.learnmore-button {
    background: none !important;
    border: 2px solid white !important;
    border-radius: 5px;
    transition: .25s;
    width: 90%;
}

.learnmore-button:hover, .learnmore-button:focus {
    box-shadow: 0 0.5em 0.5em -0.4em #f1d5a3;
    transform: translateY(-0.25em);
}

.communities-section-container {
    display: flex;
    flex-flow: column nowrap;
    margin-left: 1em;
    margin-right: 1em
}

.communities-title {
    margin-top: 3em;
    font-family: 'Raleway', sans-serif;
}

.communities {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    opacity: 1 !important;
}

.community {
    width: 300px;
    height: 300px;
    background-size: cover;
    border-radius: 5px;
    margin: 1em
}


.community-title {
    font-size: 22px;
    font-family: 'Raleway', sans-serif;
}


.ashton-link {
    text-decoration: none;
    color: white;
    font-weight: bold
}

.ashton-link:hover {
    text-decoration: none;
    color: rgb(147, 233, 255);
    font-weight: bold
}

/* Why Nashville */
.whynashville-container {
    background-image: linear-gradient( 90deg,rgba(0, 0, 0), rgba(126, 125, 125, 0.3) ),url("../../images/nashvilledowntown.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;
    width: 100%;
    height: auto;
    padding: 2em;
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 2.5vh
}

.whynashville-text-container {
    margin-top: .5em;
    margin-left: 10vw;
    width: 75%;
    min-width: 300px;
    text-align: left;
}

.whynashville-titles {
    font-weight: bold;
    color: #f1d5a3
}

.centered-title {
    text-align: center;
}

/* Carousel Arrows */
.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid #ffffff;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-next.control-arrow:hover {
    padding-right: 1em
}


.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #ffffff;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.carousel .control-prev.control-arrow:hover {
    padding-left: 1em
}


/* Communities */

.Arrington {
    background-image: url("../../images/communities/Arrington.jpg");
}

.Arrington:hover {
    transform: scale(1.1);
}

.Brentwood {
    background-image: url("../../images/communities/Brentwood.jpg");
}

.Brentwood:hover {
    transform: scale(1.1);
}

.CollegeGrove {
    background-image: url("../../images/communities/CollegeGrove.jpg");
}

.CollegeGrove:hover {
    transform: scale(1.1);
}

.Franklin {
    background-image: url("../../images/communities/Franklin.jpg");
}

.Franklin:hover {
    transform: scale(1.1);
}

.MountJuliet {
    background-image: url("../../images/communities/MountJuliet.jpg");
}

.MountJuliet:hover {
    transform: scale(1.1);
}

.Murfreesboro {
    background-image: url("../../images/communities/Murfreesboro.jpg");
}

.Murfreesboro:hover {
    transform: scale(1.1);
}

.Nashville {
    background-image: url("../../images/communities/Nashville.jpg");
}

.Nashville:hover {
    transform: scale(1.1);
}

.Nolensville {
    background-image: url("../../images/communities/Nolensville.jpg");
}

.Nolensville:hover {
    transform: scale(1.1);
}

.SpringHill {
    background-image: url("../../images/communities/SpringHill.jpg");
}

.SpringHill:hover {
    transform: scale(1.1);
}

.ThompsonStation {
    background-image: url("../../images/communities/ThompsonStation.jpg");
}

.ThompsonStation:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 1200px)
{
    .angela-img-container {
        width: 90%;
    }

    .angela-profiletext-container {
        width: 90%;
        display: flex;
        flex-flow: column nowrap;
    }
}

@media only screen and (max-width: 600px)
{
    .carousel-details {
        display: flex;
        flex-flow: column nowrap;
    }

    .angela-img-container {
        display: none;
    }

    .snippets-container {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        margin: 0;
    }

    .whynashville-text-container {
        width: 100%;
        padding: 10px;
        margin-left: 0;
    }

    .whynashville-container {
        background-size: cover;
        background-image: linear-gradient( 90deg,rgba(0, 0, 0), rgba(31, 31, 31, 0.6) ),url(/static/media/nashvilledowntown.52ea6f6a.jpg);
    }
}

@media only screen and (max-width: 400px) {
    h1 {
        font-size: 26px !important;
    }

    .angela-profiletext-container h1, .angela-profiletext-container p {
        width: 94%;
    }

    .community {
        width: 200px;
        height: 200px;
    }

    .learnmore-button {
        margin: auto;
    }

    .snippet-item {
        width: 250px;
        height: auto;
        padding: 8px;
        font-size: 14px;
    }

    .snippets-container {
        margin-top: 16px;
    }

    .angela-profiletext-container {
        font-size: 14px;
        margin: auto;
        margin-top: 16px;
        text-align: center;
    }

    .whynashville-container {
        padding: 16px;
    }

    .mobile-carousel {
        display: none !important;
    }

    
}

