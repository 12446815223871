.page-container {
    height: 100%;
    width: 100%
}

.homestop-background {
    background-image: url("../../images/NashvilleSkyline3.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    justify-content: center;
    height: 500px
}

.featuredhomes-title {
    text-align: center;
    padding-top: 3em;
    color: white;
    font-size: 4em;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    text-shadow: 3px 3px 3px black;
}

.cards-container {
    font-family: 'Raleway', sans-serif;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.home-card {
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    margin: 3em;
    width: 30em;
}

.home-card-header {
    background-color: #1d1d1d !important;
    color: white
}

.home-street {
    font-size: 25px
}


.card-body {
    text-align: center;
    font-size: 20px;
}

.cardimgback {
    background: #0000004d !important;
}

.homecard-link {
    color: black;
}

.homecard-link:link {
    color: black !important;
    text-decoration: none !important;
}

.homecard-link:visited {
    color: black !important;
    text-decoration: none !important;
}


.home-card-link:hover {
    color: black !important;
    text-decoration: none !important;
}

.addhome-button {
    height: 70px;
    width: 300px;
    background:  #FED3CD !important;
    border: none !important;
    color: black !important;
    font-size: 30px !important;
    font-weight: bold !important;
    border-radius: 5px;
    transition: .2s ease-in;
    margin-top: .5em;
}

.addhome-button:hover, .addhome-button:focus {
    box-shadow: 0 0.5em 0.5em -0.4em #000000;
    transform: translateY(-0.25em);
}

@media only screen and (max-width: 600px)
{
    .homestop-background {
        background-size: cover
    }
    .home-card {
        width: auto;
    }

    .featuredhomes-title {
        font-size: 48px;
    }
}

