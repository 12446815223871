.header-container-white {
    position: fixed !important;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    z-index: 1000;
    width: 100%;
    text-shadow: hsl(228, 39%, 15%,.286)  1px 1px 1.5px !important;
    color: rgb(24, 30, 55) !important;
    font-size: 18px;
    background-color:  rgba(255, 255, 255, 0.85) !important;
    
}
.header-container-clear {
    position: fixed !important;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    z-index: 1000;
    width: 100%;
    text-shadow: rgba(36, 35, 35, 0.286)  1px 1px 1.5px !important;
    color: rgb(24, 30, 55) !important;
    font-size: 18px;
    font-weight: bold !important;
}

.navItem {
    color: rgb(24, 30, 55) !important
}

.navbar-style {
    color: rgb(24, 30, 55) !important;
    text-decoration: none !important;
}

.navbar-style2 {
    color: white !important;
    text-decoration: none !important;
}

.middle
{
    position: relative;
}

.middle:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color:rgb(24, 30, 55);
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}
.middle:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.app-nav-toggler {
    z-index: 1000 !important;
}

@media only screen and (max-width: 800px) {
    .navbar-style img {
        height: 50px;
    }
}


