.footer-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    background-image:linear-gradient( rgb(24, 30, 55,.92), rgb(24, 30, 55,.92) ), url("../../images/Curve3.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    height: auto;
    padding: 1em;
    font-family: 'Raleway', sans-serif;
    
}

.gradient-border {
    height: 2em;
    background: linear-gradient(108.42deg, #f1d5a3 50.45%, #fff3f3 106.68%);
}

.footer-title {
    margin-top: 1em;
    font-weight: bold;
}

.contact-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    margin-top: 1em;
}

.contact-item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.contact-item-p {
    height: 40px;
    padding: 6px
}

.social-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.remax-logo-container {
    display: flex;
    width: auto;
    justify-content: center;
}

.remax-logo {
    background-image: url("../../images/RemaxAdvantage.jpg");
    height:75px;
    width: 365px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 5px;
    margin: 1em
}

.footer-subitem {
    margin: 1em;
}

@media only screen and (max-width: 500px) {
    .remax-logo {
        height: 60px;
        width: 280px;
    }
}

@media only screen and (max-width: 400px) {
    .footer-title {
        font-size: 20px;
    }

    .h4, h4 {
        font-size: 16px;
        font-weight: bold;
    }
}


