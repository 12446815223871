.recognitiontop-background {
    background-image:linear-gradient( rgba(0, 0, 0, 0.1), rgba(44, 41, 41, 0.952) ), url("../../images/angela/AngelaOffice.JPG");
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    height: 100vh
}

.recognition-page-container {
    padding: 2vw
}

.recognition-title {
    text-align: center;
    padding-top: 80vh;
    color: white;
    font-size: 4em;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    text-shadow: 3px 3px 3px black;
}


.media-container {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
}

.media-title {
    text-align: left;
    font-family: 'Raleway', sans-serif;
    font-size: 50px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(92, 92, 92, 0.507);
}

.media-div {
    display: flex;
    flex-flow: row wrap;
    padding: 2em
}

.media-div-text {
    margin-left: 1vw;
    font-family: 'Raleway', sans-serif;
    font-size: 25px;
    width: 43vw
}

.media-div-img {
    margin-left: 10vw
}

.soar-award {
    width: 400px;
}

.media-bold {
    font-family: "Sacramento";
    font-size: 90px;
    color: #FED3CD;
    font-weight: normal !important;
    text-shadow: none !important
}

.designation-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.designation-item {
    margin: 1em
}
.awards-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.awards-title {
    text-align: left;
    font-family: 'Raleway', sans-serif;
    font-size: 50px;
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(92, 92, 92, 0.507);
}

.halloffame-container {
    margin: 2em;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.hof-item {
    margin: 1em
}

.hof-text {
    font-size: 25px;
    font-weight: bold;
}

.yearawards-container {
    display: flex;
    flex-flow: row wrap;
    margin: auto
}

.yearaward-item {
    margin: 1em;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    
}

.gradient-border-media {
    height: 1em;
    background: linear-gradient(108.42deg, #383838 32.45%, #bebebe 106.68%);
    margin-bottom: 4em;
    margin-top: 4em
}

@media only screen and (max-width: 600px)
{
    .recognitiontop-background {
        height: 50vh;
    }

    .recognition-title {
        padding-top: 50vw;
    }

    .mediatext-div {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
    }

    .media-container img {
        width: 60vw;
    }

    .designation-item {
        width: 30vw;
        height: auto;
    }

    .halloffame-container img {
        width: 30vw;
        height: auto
    }

    .yearawards-container img {
        width: 30vw;
        height: auto
    }
}
